import { DcBaseComponent } from '@deleteagency/dc';

const DELAY = 20000;

export default class ReviewsPresenterComponent extends DcBaseComponent {
    static getNamespace() {
        return 'reviews-presenter';
    }

    static getRequiredRefs() {
        return ['items', 'track'];
    }

    onInit() {
        this._currentIndex = 0;
        this._isPaused = false;
        this.items = [...this.refs.items];
        if (this.items.length > 0) {
            this._lastIndex = this.items.length - 1;
            this._changeTimeout = setTimeout(this._change, DELAY);
            this.addListener(this.element, 'mouseover', this._onMouseOver);
            this.addListener(this.element, 'mouseleave', this._onMouseLeave);
        }
    }

    _onMouseOver = () => {
        this._isPaused = true;
    };

    _onMouseLeave = () => {
        this._isPaused = false;
    };

    _change = () => {
        if (!this._isPaused) {
            const currentItem = this.items[this._currentIndex];

            let nextIndex = this._currentIndex + 1;
            if (nextIndex > this._lastIndex) {
                nextIndex = 0;
            }

            const nextItem = this.items[nextIndex];

            currentItem.classList.remove('is-active');
            nextItem.classList.add('is-active');

            this.refs.track.style.transform = `translateX(-${nextIndex * 100}%)`;
            this._currentIndex = nextIndex;
        }

        this._changeTimeout = setTimeout(this._change, DELAY);
    };

    onDestroy() {
        clearTimeout(this._changeTimeout);
    }
}
